import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {getLocations, getLocation, createLocation, updatePlate} from '../functions/location'
import GoogleApiWrapper from '../components/googleMap'
import { Link, useHistory } from 'react-router-dom'
import { Table, Button, Menu, List, Select} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const Location = () => {
    const { user } = useSelector((state) => ({...state}));
    const [newlocation, setNewLocation] = useState('');
    const [location, setLocation] = useState('');
    const [company, setCompany] = useState('');
    const [lat, setLat] = useState('');
    const [lon, setLon] = useState('');

    useEffect(() => {loadLocations()}, [])

    const loadLocations = () => getLocations().then((p) => setLocation(p.data));

    const history = useHistory();

    const companyOptions = (
        <Menu>
            {location && location.map(item => (
                <Menu.Item key={location.id}>
                    {location.location}
                </Menu.Item>
            ))}
        </Menu>
    )

    const columns = [
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
        },
        {
            title: 'Latitude',
            dataIndex: 'lat',
            key: 'lat',
        },
        {
            title: 'Longitude',
            dataIndex: 'lon',
            key: 'lon',
        },        
      ];

    const listOptions = (
        <Menu>
            <Menu.Item key="1">
                Whitelist
            </Menu.Item>
            <Menu.Item key="2">
                Blacklist
            </Menu.Item>
        </Menu>
    )

    const handleSubmit = (e) => {
        e.preventDefault();
        createLocation({location})
        .then((res) => {

        })
        .catch(err => {
            console.log(err);
            toast.error(err.response.data)
        })
    }

    const addLocation = () => {
        history.push('/locations/add');
    }

    return(
    <div className="container-fluid">
        <br />
        <div className="row">
            <div className="col-md-6">
            <h4>
                Active Locations
            </h4>
            </div>
            <div className="col-md-6">
            <button onClick={addLocation} className='btn btn-outline-primary float-right'>Add</button>
            </div>
        </div>
        <Table columns={columns} dataSource={location} rowKey={location => location.id} />
        <GoogleApiWrapper loc={location}/>
        <div className='form_group'>
                <label>Company</label>
                <Select name="companyName" className="form-control" onClick={(e) => setCompany(e.target.value)}>
                    <option>Please Select</option>
                    {location && location.map(c => <option key={c.id} value={c.location}>{c.location}</option>)}
                </Select>
            </div>
    </div>
    ); 
};

export default Location;

