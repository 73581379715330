import axios from 'axios'

export const getLocations = async () =>
    await axios.get(`${process.env.REACT_APP_API}locations`);

export const getLocation = async (id) =>
    await axios.get(`${process.env.REACT_APP_API}locations/${id}`);

export const createLocation = async (locationData) =>
    await axios.post(`${process.env.REACT_APP_API}locations`, locationData);

//export const updatePlate = async (id, locationData) =>
//    await axios.put(`${process.env.REACT_APP_API}locations/${id}`, locationData);