import axios from 'axios'

export const getAllLeaks = async () =>
    await axios.get(`${process.env.REACT_APP_API}gasLeak`);

export const getFilteredLeaks = async (data) =>
    await axios.post(`${process.env.REACT_APP_API}gasLeak`, data);

export const updateLeakValidity = async (id, data) =>
    await axios.put(`${process.env.REACT_APP_API}gasLeak/${id}`, data);



    
