import React, { Component, useEffect, useState } from "react";
import { getAllLeaks, getFilteredLeaks, updateLeakValidity } from "../functions/gasLeaks";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify'
import { Table, Button, Space, DatePicker } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import { ExportOutlined, LikeOutlined, DislikeOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';

function mysqlUTCTime2Local(utcTime) {
    var dt = utcTime.split(/[- :TZ]/);
    var dt2 = new Date(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5]);
    return new Date(dt2.getTime() - ( dt2.getTimezoneOffset() * 60000 ));
 }

 const GasLeak = () => {
    const [values, setValues] = useState({
        leaks: []
    });

    //const {cars} = values;

    useEffect(() => {loadLeaks()}, []);

    const loadLeaks = () => getAllLeaks().then((p) => setValues({...values, leaks: p.data}));

    const onChange = (dates, dateStrings) => {
    if(dates){
        setValues({...values, leaks: []})

        let reqBody = {
          dates: [dates[0].set({h: 0, m: 0, s: 0}), dates[1].set({h: 23, m: 59, s: 59})]
        };

        getFilteredLeaks(dates)
          .then(response => {
            console.log(response.data);
            setValues({...values, leaks: response.data});
          })
          .catch(e => {
            console.log(e);
          });

        }else {
            loadLeaks();
        }
        //console.log(cars);
    }

    const downloadExcel = () => {
        const data = values.leaks ? values.leaks : '';//tabular data
        var option={};
        let dataTable = [];

        if (data) {
          for (let i in data) {
            if(data){
              let obj = {
                           'Date': mysqlUTCTime2Local(data[i].date_time).toLocaleString(),
                           'Camera': data[i].camera_name,
                           'Rate': data[i].rate_fcm,
                           'Video Link': data[i].url,
              }
              dataTable.push(obj);
            }
          }
        }

        option.fileName = 'Organization Information'
        option.datas=[
          {
            sheetData:dataTable,
            sheetName:'sheet',
                   sheetFilter:['Date', 'Camera', 'Rate', 'Video Link'],
                   sheetHeader:['Date', 'Camera', 'Rate', 'Video Link'],
          }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      }

    const { RangePicker } = DatePicker;

    const validClick = (e, value) => {
      const data = {
        'valid': value
      }
      updateLeakValidity(e, data)
      .then((res) => {
        if(value == 1){
          toast.success(`Gas Leak has been validated`);
        }else{
          toast.success(`Gas Leak has been negated`);
        }
      })
      .catch(err => {
        console.log(err);
      })      
    }

    const columns = [
        {
          title: 'Date ('+Intl.DateTimeFormat().resolvedOptions().timeZone+')',
          dataIndex: 'date_time',
          key: 'date_time',
          render: text => <a>{mysqlUTCTime2Local(text).toLocaleString()}</a>
        },
        {
          title: 'Camera',
          dataIndex: 'camera_name',
          key: 'camera_name',
        },
        {
          title: 'Rate',
          dataIndex: 'rate_fcm',
          key: 'rate_fcm',
        },
        {
          title: 'Video Link',
          dataIndex: 'url',
          key: 'url',
          render: (text, record) => (
            <Space size="right">
              {record.url != '' ? <a href={record.url}>Video</a> : <p className="text-danger">No Video</p>}
            </Space>
          ),
        },
        {
          title: "Valid",
          dataIndex: 'valid',
          key: 'valid',
          render: (text, record) =>(               
            <Space size='middle'>
              {record.valid == 0 ? <>
                <Button onClick={() => {validClick(record.id, 1)}} className="float-right mr-2" icon={<LikeOutlined />}></Button>
                <Button onClick={() => {validClick(record.id, 0)}} className="float-right mr-2" icon={<DislikeFilled style={{color: "#4E89FF"}}/>}></Button>                
                </> : record.valid == 1 ? <>
                  <Button onClick={() => {validClick(record.id, 1)}} className="float-right mr-2" icon={<LikeFilled style={{color: "#4E89FF"}}/>}></Button>
                  <Button onClick={() => {validClick(record.id, 0)}} className="float-right mr-2" icon={<DislikeOutlined />}></Button>
                </> : <>
                  <Button onClick={() => {validClick(record.id, 1)}} className="float-right mr-2" icon={<LikeOutlined />}></Button>
                  <Button onClick={() => {validClick(record.id, 0)}} className="float-right mr-2" icon={<DislikeOutlined />}></Button>                  
                </>                    
              }   
            </Space>         
          ),
        }
      ];


    return (

        <div className="list row">
          <div className="col-md-6">
            <div className="input-group mb-3">

            </div>
          </div>
          <div className="col-md-12">

            <div className="row">
              <div className="col-sm">
                <h4>Gas Leaks</h4>
              </div>
              <div className="col-sm">
                <RangePicker onChange={onChange}/>
              </div>
              <div className="col-sm">
              <Button onClick={downloadExcel} className="float-right mr-2" icon={<ExportOutlined />}>Export</Button>
            </div>
            </div>
              <Table columns={columns} dataSource={values.leaks} rowKey={leaks => leaks.id} />
          </div>
        </div>
      );
 };

 export default GasLeak;
