import React from "react";
import Logo from '../images/Clean-Connect-Logo.jpg'
import {Statistic, Row, Col} from 'antd'
import GoogleApiWrapper from '../components/googleMap'
import SidebarNav from '../components/nav/sidebarNav'

const Home = () => (
  <div>

    <GoogleApiWrapper />
    
    <Row>
      <img src={Logo} alt="Logo image" className="d-block mx-auto img-fluid w-50"/>
    </Row>
  </div>
);

export default Home;
