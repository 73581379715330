import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {getPlates, getPlate, createPlate, updatePlate} from '../functions/plate'
import { Link } from 'react-router-dom'
import { Table, Button, Dropdown, Menu} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const initialState = {
    id: '',
    plate: '',
    company_name: '',
    listType: ''
}

const SinglePlate = (props) => {
    const { user } = useSelector((state) => ({...state}));
    const [values, setValues] = useState(initialState)

    const {id, plate, company_name, listType} = values;
    const companies = ['XOG', 'Midstream', 'Contractor', 'Inspector']
    const listTypes = ['Whitelist', 'Blacklist']

    useEffect(() => {loadPlates()}, [])

    const loadPlates = () => getPlate(props.match.params.id).then((p) => setValues(p.data));   

    const companyOptions = (
        <Menu>
            <Menu.Item key="1">
                XOG
            </Menu.Item>
            <Menu.Item key="2">
                Midstream
            </Menu.Item>
            <Menu.Item key="3">
                Contractor
            </Menu.Item>
            <Menu.Item key="4">
                Inspector
            </Menu.Item>
        </Menu>
    )

    const listOptions = (
        <Menu>
            <Menu.Item key="1">
                Whitelist
            </Menu.Item>
            <Menu.Item key="2">
                Blacklist
            </Menu.Item>
        </Menu>
    )

    const handleSubmit = (e) => {
       e.preventDefault();
       updatePlate(id, values)
       .then((res) => {
          toast.success(() => <div>Updated <br/> License Plate: {res.data.plate} <br />Company: {res.data.company_name}<br />List Type {res.data.listType}</div>)
        })
       .catch((err) => {
           console.log(err);
           if(err.response.status === 400) toast.error(err.response.data);
       })
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
        console.log(values);
    }

    return(
    <div className="container-fluid">
        <form onSubmit={handleSubmit}>
            <div className="form_group">                
                <h3>{plate}</h3>
                <br />
                <br />
                <div className="form-group">
                    <label>Company</label>
                    <p className="float_middle">{company_name}</p>
                    <select name="company_name" className="form-control" onClick={handleChange}>
                        <option>Please Select</option>
                        {companies && companies.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />
                <div className="form-group">
                    <label>List Type</label>
                    <p className="float_middle">{listType}</p>
                    <select name="listType" className="form-control" onClick={handleChange}>
                        <option>Please Select</option>
                        {listTypes && listTypes.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />

                <br />
                <button className='btn btn-outline-primary'>Save</button>
            </div>
        </form>
    </div>
    ); 
};

export default SinglePlate;
