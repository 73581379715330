import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Menu} from 'antd'
import { 
    AppstoreOutlined,
     SettingOutlined,
   UserAddOutlined,
   TableOutlined,
   AlignLeftOutlined,
   FormOutlined,  
   DashboardOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import AdminNav from '../nav/adminNav'

const { SubMenu } = Menu;

const SidebarNav = () => {

    const [current, setCurrent] = useState('')

    let {user} = useSelector((state) => ({...state}));
    let history = useHistory();

    const handleClick = e => {
        setCurrent(e.key);
    };

    return(     
        <>
        {user && (   
        <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        style={{ width: 256 }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        
        <SubMenu key="sub1" title="LPR">
          <Menu.Item key="1" icon={<DashboardOutlined />}><Link to="/cars/Dashboard">Dashboard</Link></Menu.Item>
          <Menu.Item key="2" icon={<AlignLeftOutlined />}><Link to="/cars">Log</Link></Menu.Item>
          <Menu.Item key="3" icon={<FormOutlined />}><Link to="/plates">Plates</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<TableOutlined />} title="TLM">
          <Menu.Item key="5" icon={<DashboardOutlined />}><Link to="/tankLevel">Dashboard</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<TableOutlined />} title="Gas Leak">
          <Menu.Item key="7" icon={<AlignLeftOutlined />}><Link to="/gasLeak">Log</Link></Menu.Item>
        </SubMenu>

      </Menu>
        )}
        </>
)}

export default SidebarNav