import React, { Component, useEffect, useState, PureComponent } from "react";
import { getLevel } from "../functions/tankLevel";
import { Link } from "react-router-dom";
import { Table, Button, Space, DatePicker } from 'antd';
import { ExportOutlined, setTwoToneColor } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment, { unix } from "moment";


function filteredUTCTime2Local(utcTime) {
    var dt = utcTime.split(/[-, ,:]/);
    var dt2 = new Date(dt[0], dt[1]-1, dt[2], dt[3], dt[4]);
    return new Date(dt2.getTime() - ( dt2.getTimezoneOffset() * 60000 ));
 }

 const TankLevel = () => {
    const [values, setValues] = useState({
        levels: [],
        cardActive: 1
    });

    //const {cars} = values;

    useEffect(() => {loadLevels()}, []);

    const loadLevels = () => getLevel().then((p) => {
       for (let i in p.data) {
          p.data[i].filtered = filteredUTCTime2Local(p.data[i].filtered).toLocaleString()
       }
       setValues({...values, levels: p.data})
   });

    const onSelection = (id) => {
        getLevel(id)
        .then(response => {
          console.log(response.data);
          for (let i in response.data) {
             response.data[i].filtered = filteredUTCTime2Local(response.data[i].filtered).toLocaleString()
          }
          setValues({levels: response.data, cardActive: id});
        })
        .catch(e => {
          console.log(e);
        });

    }

    const tankSelection = () => {
        return(
            <>
        <div className="row">
            <div className="col-sm-3">
                <div key="1" className={values.cardActive === 1 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(1)} style={{ cursor: "pointer" }}>
                    <div className="card-block">
                        <h2 className="card-title">Tank 1</h2>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div key="2" className={values.cardActive === 2 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(2)} style={{ cursor: "pointer" }}>
                    <div className="card-block">
                        <h2 className="card-title">Tank 2</h2>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className={values.cardActive === 3 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(3)} style={{ cursor: "pointer" }}>
                    <div className="card-block">
                        <h2 className="card-title">Tank 3</h2>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className={values.cardActive === 4 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(4)} style={{ cursor: "pointer" }}>
                    <div className="card-block">
                        <h2 className="card-title">Tank 4</h2>
                    </div>
                </div>
            </div>
        </div>
        {/*
            <div className="row">
                <div className="col-sm-3">
                    <div className={values.cardActive === 5 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(5)} style={{ cursor: "pointer" }}>
                        <div className="card-block">
                            <h2 className="card-title">Tank 5</h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={values.cardActive === 6 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(6)} style={{ cursor: "pointer" }}>
                        <div className="card-block">
                            <h2 className="card-title">Tank 6</h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={values.cardActive === 7 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(7)} style={{ cursor: "pointer" }}>
                        <div className="card-block">
                            <h2 className="card-title">Tank 7</h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={values.cardActive === 8 ? "cardActive mb-3 text-center" : "card mb-3 text-center"} as="a" onClick={() => onSelection(8)} style={{ cursor: "pointer" }}>
                        <div className="card-block">
                            <h2 className="card-title">Tank 8</h2>
                        </div>
                    </div>
                </div>
            </div>
            */}
            </>
        )
    }

    const handleClick = (e) => {
        console.log(e);
        console.log(e.activePayload[0].payload.url);
    }

    const graph = () => {
        return(
            <ResponsiveContainer width={'99%'} height={400}>
            <LineChart
                width={500}
                height={300}
                data={values.levels}
                onClick={handleClick}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="filtered" domain={['auto','auto']} angle={45} dx={15} dy={20}
                       tickFormatter={(date) => moment.utc(date).format('MM/DD HH:mm')} />
                <YAxis label="Inches" yAxisId="left" domain={[0, 144]} tickCount={14}/>
                <Tooltip />
                <Legend />
                <Line yAxisId="left" type="monotone" dataKey="level_inch" stroke="#8884d8" strokeWidth={4} dot={false} name="Camera"/>
                <Line yAxisId="left" type="natural" dataKey="oil_level" stroke="#82ca9d" strokeWidth={2} dot={false} name="Ground Truth"/>
            </LineChart>
          </ResponsiveContainer>
        )
    }

    return (
        <div className="container-fluid" width="100%" height="100%">
            {tankSelection()}
            {graph()}
        </div>
      );
 };

 export default TankLevel;
