import axios from 'axios'

export const getPlates = async () =>
    await axios.get(`${process.env.REACT_APP_API}plates`);

export const getPlate = async (id) =>
    await axios.get(`${process.env.REACT_APP_API}plates/${id}`);

export const createPlate = async (plateData) =>
    await axios.post(`${process.env.REACT_APP_API}plates`, plateData);

export const updatePlate = async (id, plateData) =>
    await axios.put(`${process.env.REACT_APP_API}plates/${id}`, plateData);
