import React from 'react';
import AdminNav from '../../components/nav/adminNav';

const AdminDashboard = () => {
    return (
        <>
            <AdminNav />
        </>
    )
}

export default AdminDashboard;