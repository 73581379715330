import React from 'react';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';


class GoogleMapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    }
    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }
  onMarkerClick = (props, marker, e) => {
      console.log(this.props.loc)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  }
  render() {
    const style = {
      width: '50vw',
      height: '75vh',
      'marginLeft': 'auto',
      'marginRight': 'auto'
    }
    
    return (
      <Map
        item
        xs = { 12 }
        style = { style }
        google = { this.props.google }
        onClick = { this.onMapClick }
        zoom = { 10 }
        initialCenter = {{ lat: 40.200148, lng: -104.852853 }}
      >
        <Marker
          onClick = { this.onMarkerClick }
          title = { 'Vetting' }
          position = {{ lat: 40.402148, lng: -104.652853 }}
          name = { 'Vetting' }
        />
        <Marker
          onClick = { this.onMarkerClick }
          title = { 'Sherley' }
          position = {{ lat: 40.433369, lng: -104.674231 }}
          name = { 'Changing Colors Garage' }
        />
        <Marker
          onClick = { this.onMarkerClick }
          title = { 'Livingston' }
          position = {{ lat: 39.978422, lng: -105.039339 }}
          name = { 'Changing Colors Garage' }
        />
      </Map>
      
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyApPRKaqerAP3r_iUJMcfh6VDsto8F5n8g'
})(GoogleMapsContainer)