import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {getPlates, getPlate, createPlate, updatePlate} from '../functions/plate'
import { Link } from 'react-router-dom'
import { Table, Button, Menu} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const Plate = () => {
    const { user } = useSelector((state) => ({...state}));
    const [plateNum, setPlateNum] = useState('');
    const [company, setCompany] = useState('');
    const [listType, setListType] = useState('');
    const [plates, setPlates] = useState('');

    useEffect(() => {loadPlates()}, [])

    const loadPlates = () => getPlates().then((p) => setPlates(p.data));

    const plate = {plateNum, company, listType}

    const companies = ['XOG', 'Midstream', 'Contractor', 'Inspector']
    const listTypes = ['Whitelist', 'Blacklist']

    const companyOptions = (
        <Menu>
            <Menu.Item key="1">
                XOG
            </Menu.Item>
            <Menu.Item key="2">
                Midstream
            </Menu.Item>
            <Menu.Item key="3">
                Contractor
            </Menu.Item>
            <Menu.Item key="4">
                Inspector
            </Menu.Item>
        </Menu>
    )

    const listOptions = (
        <Menu>
            <Menu.Item key="1">
                Whitelist
            </Menu.Item>
            <Menu.Item key="2">
                Blacklist
            </Menu.Item>
        </Menu>
    )

    const handleSubmit = (e) => {
        e.preventDefault();
        createPlate({plate})
        .then((res) => {
            setPlateNum('');
            setCompany('');
            setListType('');
            toast.success(`"${res.data.plate}" is created`);
        })
        .catch(err => {
            console.log(err);
            toast.error(err.response.data)
        })
    }

    const plateForm = () => (
         <form onSubmit={handleSubmit}>
            <div className="form_group">                
                <label>Plate Number</label>
                <input 
                    type="text" 
                    className="form-control" 
                    value={plateNum} 
                    onChange={e => setPlateNum(e.target.value)}
                    autoFocus
                    required
                />
                <br />
                <div className="form-group">
                    <label>Company</label>
                    <select name="company_name" className="form-control" onClick={(e) => setCompany(e.target.value)}>
                        <option>Please Select</option>
                        {companies && companies.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />
                <div className="form-group">
                    <label>List Type</label>
                    <select name="listType" className="form-control" onClick={(e) => setListType(e.target.value)}>
                        <option>Please Select</option>
                        {listTypes && listTypes.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />

                <br />
                <button className='btn btn-outline-primary'>Save</button>
            </div>
        </form>
    )

    return(
    <div className="container-fluid">
        <h4>
            Create Plate
        </h4>
        <div>
        <form onSubmit={handleSubmit}>
            <div className="form_group">                
                <label>Plate Number</label>
                <input 
                    type="text" 
                    className="form-control" 
                    value={plateNum} 
                    onChange={e => setPlateNum(e.target.value)}
                    autoFocus
                    required
                />
                <br />
                <div className="form-group">
                    <label>Company</label>
                    <select name="company_name" className="form-control" onClick={(e) => setCompany(e.target.value)}>
                        <option>Please Select</option>
                        {companies && companies.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />
                <div className="form-group">
                    <label>List Type</label>
                    <select name="listType" className="form-control" onClick={(e) => setListType(e.target.value)}>
                        <option>Please Select</option>
                        {listTypes && listTypes.map(c => <option key={c} value={c}>{c}</option>)}
                    </select>
                </div>
                <br />
                <button className='btn btn-outline-primary'>Save</button>
            </div>
        </form>
        </div>
        
    </div>
    ); 
};

export default Plate;

