import React, {useEffect} from 'react'
import {Switch, Route} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import Home from './pages/Home'
import Header from './components/nav/header'
import SidebarNav from './components/nav/sidebarNav'
import RegisterComplete from './pages/auth/RegisterComplete'
import ForgotPassword from './pages/auth/ForgotPassword'
import Plate from './pages/Plate'
import singlePlate from './pages/singlePlate'
import CarsList from './pages/carLog1'
import UserRoute from './components/routes/UserRoute'
import AdminRoute from './components/routes/AdminRoute'
import CarDashboard from './pages/carDashboard'
import Location from './pages/locations'
import addLocation from './pages/addLocation'
import GasLeak from './pages/gasLeak'
import TankLevel from './pages/tankLevel'
import AdminDashboard from './pages/admin/AdminDashboard';

import { auth } from './firebase'
import {useDispatch} from 'react-redux'
import {currentUser} from './functions/auth'


const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if(user) {
        console.log(user)
        const idTokenResult = await user.getIdTokenResult()
        currentUser(idTokenResult.token)
        .then((res) => {
            console.log(res)
            dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  email: user.email,
                  token: idTokenResult.token,
                  role: res.data.role,
                  _id: res.data.id
                },
            })
        })
        .catch(err => console.log(err))
      }
    })
    return () => unsubscribe();
  }, [dispatch])

  return (
    
    <>
    <Header />
    <ToastContainer />
      <div className="row">
        <div className="col-md-2">
          <SidebarNav />
        </div>
        <div className="col">
      
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path='/register/complete' component={RegisterComplete} />
        <Route exact path='/forgot/password' component={ForgotPassword} />
        <UserRoute exact path='/plates' component={Plate} />
        <UserRoute exact path='/plates/:id' component={singlePlate} />
        <UserRoute exact path='/cars/' component={CarsList} />
        <UserRoute exact path='/cars/dashboard' component={CarDashboard} />
        <UserRoute exact path='/locations' component={Location} />
        <UserRoute exact path='/locations/add' component={addLocation} />
        <UserRoute exact path='/gasLeak' component={GasLeak} />
        <UserRoute exact path='/tankLevel' component={TankLevel} />
        <UserRoute exact path='/tankLevel/:id' component={TankLevel} />
        <AdminRoute exact path='/admin/dashboard' component={AdminDashboard} />
      </Switch>
      </div>
      </div>
     </>
    
  );
}

export default App;
