import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Menu} from 'antd'
import { 
    AppstoreOutlined,
     SettingOutlined,
   UserAddOutlined,
   TableOutlined,
   AlignLeftOutlined,
   FormOutlined,  
   DashboardOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";

const { SubMenu } = Menu;

const AdminNav = () => {

    const [current, setCurrent] = useState('')

    let {user} = useSelector((state) => ({...state}));
    let history = useHistory();

    const handleClick = e => {
        setCurrent(e.key);
    };

    return(     
        <>
        {user && (   
        <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        style={{ width: 256 }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        
        <SubMenu key="sub1" title="Admin">
          <Menu.Item key="1" icon={<DashboardOutlined />}><Link to="/admin/Dashboard">Dashboard</Link></Menu.Item>
          <Menu.Item key="2" icon={<AlignLeftOutlined />}><Link to="/admin/Locations">Locations</Link></Menu.Item>
          <Menu.Item key="3" icon={<FormOutlined />}><Link to="/admin/Users">Users</Link></Menu.Item>
        </SubMenu>  
      </Menu>
        )}
        </>
)}

export default AdminNav