import React, { Component, useEffect, useState } from "react";
import CarDataService from "../services/car.service";
import { Link } from "react-router-dom";
import { Table, Button, Space, DatePicker } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import { ExportOutlined, setTwoToneColor } from '@ant-design/icons';

function mysqlUTCTime2Local(utcTime) {
    var dt = utcTime.split(/[- :TZ]/);
    var dt2 = new Date(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5]);
    return new Date(dt2.getTime() - ( dt2.getTimezoneOffset() * 60000 ));
 }

 const CarLog1 = () => {
    const [values, setValues] = useState({
        cars: []
    });

    //const {cars} = values;

    useEffect(() => {loadCars()}, []);

    const loadCars = () => CarDataService.getAll().then((p) => setValues({...values, cars: p.data}));

    const onChange = (dates, dateStrings) => {
    if(dates){
        setValues({...values, cars: []})
        
        let reqBody = {
          dates: [dates[0].set({h: 0, m: 0, s: 0}), dates[1].set({h: 23, m: 59, s: 59})]
        };

        CarDataService.getFiltered(dates)
          .then(response => {
            console.log(response.data);
            setValues({...values, cars: response.data});
          })
          .catch(e => {
            console.log(e);
          });
          
        }else {
            loadCars();
            console.log("Load all cars")
        } 
        //console.log(cars);
    }

    const downloadExcel = () => {
        const data = values.cars ? values.cars : '';//tabular data
        var option={};
        let dataTable = [];
    
        if (data) {
          for (let i in data) {
            if(data){
              let obj = {
                           'Date': mysqlUTCTime2Local(data[i].date).toLocaleString(),
                           'Plate': data[i].plate,
                           'Company': data[i].company_name,
                           'List Type': data[i].listType,
                           'Score': data[i].score,
              }
              dataTable.push(obj);
            }
          }
        }
        
        option.fileName = 'Organization Information'
        option.datas=[
          {
            sheetData:dataTable,
            sheetName:'sheet',
                   sheetFilter:['Date', 'Plate', 'Company', 'List Type', 'Score'],
                   sheetHeader:['Date', 'Plate', 'Company', 'List Type', 'Score'],
          }
        ];
    
        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel(); 
      }

    const { RangePicker } = DatePicker;

    const columns = [
        {
          title: 'Date ('+Intl.DateTimeFormat().resolvedOptions().timeZone+')',
          dataIndex: 'date',
          key: 'date',
          render: text => <a>{mysqlUTCTime2Local(text).toLocaleString()}</a>
        },
        {
          title: 'Plate Number',
          dataIndex: 'plate_number',
          key: 'plate_number',
          render: (text, record) => (
            <Space size="middle">
            <Link to={`/plates/${record.plate_number}`}>{text}</Link>
          </Space>
          ),
        },
        {
          title: 'Company',
          dataIndex: 'company_name',
          key: 'company_name',
        },
        {
          title: 'List',
          dataIndex: 'listType',
          key: 'listType',
        },
        {
          title: 'Score',
          dataIndex: 'score',
          key: 'score',
        },
        {
          title: 'Video Link',
          dataIndex: 'public_url',
          key: 'public_url',
          render: (text, record) => (
            <Space size="middle">
              {record.public_url != 'nan' ? <a href={record.public_url}>Video</a> : <p className="text-danger">No Video</p>}
            </Space>
          ),
        },
      ];

    
    return (

        <div className="list row">
          <div className="col-md-6">
            <div className="input-group mb-3">
  
            </div>
          </div>
          <div className="col-md-12">
            
            <div className="row">
              <div className="col-sm">
                <h4>Plate List</h4>
              </div>
              <div className="col-sm">
                <RangePicker onChange={onChange}/>
              </div>
              <div className="col-sm">
              <Button onClick={downloadExcel} className="float-right mr-2" icon={<ExportOutlined />}>Export</Button>
            </div>
            </div>
              <Table columns={columns} dataSource={values.cars} rowKey={cars => cars._id} />
          </div>
        </div>
      );
 };

 export default CarLog1;