import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {createLocation} from '../functions/location'
import { Link } from 'react-router-dom'
import { Table, Button, Dropdown, Menu} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const initialState = {
    companyName: 'XOG',
    location: '',
    lat: '',
    lon: ''
}

const AddLocation = (props) => {
    const { user } = useSelector((state) => ({...state}));
    const [values, setValues] = useState(initialState)

    const {companyName, location, lat, lon} = values;

    const handleSubmit = (e) => {
       e.preventDefault();
       createLocation(values)
       .then((res) => {
          setValues(initialState);
          toast.success(() => <div>Updated <br/> Location: {res.data.location} <br />Latitude: {res.data.lat} <br />Longitude: {res.data.lon}</div>)
        })
       .catch((err) => {
           console.log(err);
           if(err.response.status === 400) toast.error(err.response.data);
       })
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    return(
    <div className="container-fluid">
        <form onSubmit={handleSubmit}>
            <div className="form_group">                
                <h3>Location</h3>
                <br />
                <br />
                <div className="form-group">
                    <label>Location Name</label>
                    <input type="text" name="location" value={location} onChange={handleChange} />
                </div>
                <br />
                <div className="form-group">
                    <label>Latitude</label>
                    <input type="text" name="lat" value={lat} onChange={handleChange} />
                </div>
                <br />
                <div className="form-group">
                    <label>Longitude</label>
                    <input type="text" name="lon" value={lon} onChange={handleChange} />
                </div>
                <br />
                <button className='btn btn-outline-primary'>Save</button>
            </div>
        </form>
    </div>
    ); 
};

export default AddLocation;
