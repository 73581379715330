import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import { toast } from 'react-toastify'
import {Button} from 'antd'
import { MailOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {createOrUpdateUser} from '../../functions/auth'


const Login = ({history}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    
    const {user} = useSelector((state) => ({...state}));

    useEffect(() => {
        if(user && user.token) history.push('/');
    },[user])

    let dispatch = useDispatch();

    const roleBasedRedirect = (res) => {
        //  Admin redirect
        if(res.data.role === 'admin'){
            history.push('/')
        }else // User redirect
        {
            history.push('/cars')
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try{
            const result = await auth.signInWithEmailAndPassword(email, password);
            const {user} = result
            const idTokenResult = await user.getIdTokenResult();

            createOrUpdateUser(idTokenResult.token)
            .then((res) => {
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                      email: user.email,
                      token: idTokenResult.token,
                      role: res.data[0].role,
                      _id: res.data[0].id
                    },
                });
                roleBasedRedirect(res);
            })
            .catch()

            //history.push('./')
        }catch(error){
            toast.error(error.message);
            setLoading(false);
        }
    };

    const LoginForm = () => <form onSubmit={handleSubmit}>
        <input 
            type="email" 
            className="form-control" 
            value={email} 
            onChange={e => setEmail(e.target.value)} 
            autoFocus 
        />
        <br />
        <input 
            type="password" 
            className="form-control" 
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter Password" 
        />
        <br />
        <Button 
            onClick={handleSubmit} 
            type="primary" 
            className="mb-3"
            block
            shape="round"
            icon={<MailOutlined />}
            disabled={!email || password.length < 6}
            >Login with Email/Password
        </Button>
        <br />
        <Link to="/forgot/password" className="float-right text-danger">Forgot Password</Link>
    </form>

    return(
        <div className="container p-5">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    {!loading ? <h4>Login</h4> : <h4 className="text-danger">Loading...</h4>}
                    {LoginForm()}
                </div>
            </div>
        </div>
    )
}

export default Login;