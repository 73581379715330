import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import { getDashboard } from '../functions/cars'
import CarDataService from "../services/car.service";
import { Link } from 'react-router-dom'
import { Table, Button, Space} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

function mysqlUTCTime2Local(utcTime) {
    var dt = utcTime.split(/[- :TZ]/);
    var dt2 = new Date(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5]);
    return new Date(dt2.getTime() - ( dt2.getTimezoneOffset() * 60000 ));
 }

const CarDashboard = () => {
    const { user } = useSelector((state) => ({...state}));
    const [values, setValues] = useState({
        company1: '',
        companyCount1: 0,
        company2: '',
        companyCount2: 0,
        company3: '',
        companyCount3: 0,
        company4: '',
        companyCount4: 0,
        activeCompany: '',
        companyCars: [],
        companyCounts: []
    });


    useEffect(() => {loadStats()}, [])

    const loadStats = () => getDashboard().then((p) => {
        console.log(p.data);
        setValues({...values, 
          companyCounts: p.data
        });
        console.log(p.data);
        //console.log(company[1])
    });

    const onSelection = (co) => {

        let reqBody = {
            "company_name": co
          };

        CarDataService.getCompanies(reqBody)
        .then(response => {
          console.log(response.data);
          setValues({...values, companyCars: response.data});
        })
        .catch(e => {
          console.log(e);
        });
        
    }
    

    const columns = [
        {
          title: 'Date ('+Intl.DateTimeFormat().resolvedOptions().timeZone+')',
          dataIndex: 'date',
          key: 'date',
          render: text => <a>{mysqlUTCTime2Local(text).toLocaleString()}</a>
        },
        {
          title: 'Plate Number',
          dataIndex: 'plate_number',
          key: 'plate_number',
          render: (text, record) => (
            <Space size="middle">
            <Link to={`/plates/${record.plate_number}`}>{text}</Link>
          </Space>
          ),
        },
        {
          title: 'Company',
          dataIndex: 'company_name',
          key: 'company_name',
        },
        {
          title: 'List',
          dataIndex: 'listType',
          key: 'listType',
        },
        {
          title: 'Score',
          dataIndex: 'score',
          key: 'score',
        },
        {
          title: 'Video Link',
          dataIndex: 'public_url',
          key: 'public_url',
          render: (text, record) => (
            <Space size="middle">
              {record.public_url != 'nan' ? <a href={record.public_url}>Video</a> : <p className="text-danger">No Video</p>}
            </Space>
          ),
        },
      ];

    const count = () => {
        return(
            <div className="row">
                  {
                    values.companyCounts.map(({company_name, countCompanies}) => (
                      <div className="col-sm-3">
                      <div className="card mb-3" as="a" onClick={() => onSelection(company_name)} style={{ cursor: "pointer" }}>
                        <div className="card-block mb-3" id={company_name}>
                        {company_name == null ? <h2 className="card-title">Unknown</h2> : <h2 className="card-title">{company_name}</h2> }                
                        <h3 className="card-txt">{countCompanies}</h3> 
                        </div>
                      </div>
                      </div>
                    ))
                  }
              
            </div>
        )
    }

    return(
        <div className="container-fluid">
            <div>
                <h3>Dashboard page</h3>
            </div>
            {count()}    
            <Table columns={columns} dataSource={values.companyCars} rowKey={companyCars => companyCars._id} />  
      </div>
    ); 
};

export default CarDashboard;

