import http from "../http-common";

class CarDataService {
  getAll() {
    return http.get("/cars");
  }

  get(id) {
    return http.get(`/cars/${id}`);
  }

  getFiltered(data){
    return http.post("/cars", data);
  }

  getCompanies(data){
    return http.post("/dashboard", data);
  }

//  getPlate(id) {
//    return http.get(`/plate/${id}`);
//  }

//  createPlate(data) {
//    return http.post("/cars", data);
//  }

//  update(id, data) {
//    return http.put(`/plates/${id}`, data);
//  }

//  delete(id) {
//    return http.delete(`/cars/${id}`);
//  }

//  deleteAll() {
//    return http.delete(`/cars`);
//  }

//  findByTitle(title) {
//    return http.get(`/cars?plate_number=${title}`);
//  }

  findByUnknown(){
    return http.get("/cars/unknown");
  }
}

export default new CarDataService();