 import firebase from '../node_modules/firebase';

 
 // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBhutQHWYKLMeaxrMHc9fZ4SlhnM_3RYQM",
    authDomain: "cleanconnectlpr.firebaseapp.com",
    projectId: "cleanconnectlpr",
    storageBucket: "cleanconnectlpr.appspot.com",
    messagingSenderId: "324644235501",
    appId: "1:324644235501:web:90c827dcdf42545df984e4",
    measurementId: "G-QLR7C4GVHQ"
  };
  // Initialize Firebase
  //firebase.initializeApp(firebaseConfig);
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }else {
    firebase.app(); // if already initialized, use that one
  }

  const auth = firebase.auth();

  export {auth}
  
  export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
